
  import { defineComponent, ref } from 'vue'
  
  export default defineComponent({
    name: "AboutUs",
    setup() {
      const activeAccordion = ref<number | null>(null)
      
      const toggleAccordion = (index: number) => {
        activeAccordion.value = activeAccordion.value === index ? null : index
      }
      
      return {
        activeAccordion,
        toggleAccordion
      }
    }
  })
  